<template>
  <div id="about">
    <div class="about_banner" v-for="item in banners" :key="item.id" v-show="aboutsTabIndex === item.id">
      <el-image style="width: 100%;height: 219px;border:0;" :src="item.url" fit="fill"/>
    </div>
    <!--  关于安居主体  -->
    <div class="about_main">
      <div class="main_warp">
        <div class="main_title">
          <div class="main_title_path">网站首页/关于安居/{{ aboutTabs[aboutsTabIndex].title }}</div>
          <div class="picture_album">
            <el-link v-if="pictureAlbum" :href="preview+pictureAlbum" target="_blank" :underline="false">公司画册
            </el-link>
          </div>
        </div>
        <div class="main_content">
          <!--    tab切换      -->
          <div class="contact_left">
            <div class="left_item_warp" v-for="item in aboutTabs" :key="item.id"
                 :class="[aboutsTabIndex === item.id ? 'leftItemWarpAction':'']" @click="aboutsTabClick(item.id)">
              {{ item.title }}
            </div>
          </div>
          <!--    tab展示内容      -->
          <div class="contact_right">
            <!--    公司介绍      -->
            <div v-show="0 === aboutsTabIndex" class="right_item_warp">
              <div class="right_item">
                <div class="right_item_title">
                  <div class="item_title_txt">公司介绍</div>
                  <div class="item_title_null"></div>
                </div>
                <div class="right_item_contact" v-html="coIntroduction">
                </div>
              </div>
              <div class="right_item">
                <div class="right_item_title">
                  <div class="item_title_txt">公司定位</div>
                  <div class="item_title_null"></div>
                </div>
                <div class="right_item_contact" v-html="coPositioning">
                </div>
              </div>
            </div>
            <!--    企业文化      -->
            <div v-show="1 === aboutsTabIndex" class="right_item_warp">
              <div class="right_item">
                <div class="right_item_title">
                  <div class="item_title_txt">企业文化</div>
                  <div class="item_title_null"></div>
                </div>
                <div class="right_item_contact" v-html="coCulture">
                </div>
              </div>
            </div>
            <!--    组织架构      -->
            <div v-show="2 === aboutsTabIndex" class="right_item_warp">
              <div class="right_item">
                <div class="right_item_title">
                  <div class="item_title_txt">组织架构</div>
                  <div class="item_title_null"></div>
                </div>
                <div class="right_item_contact" v-html="orStructure">
                </div>
              </div>
            </div>
            <!--    主营业务      -->
            <div v-show="3 === aboutsTabIndex" class="right_item_warp">
              <div class="right_item">
                <div class="right_item_title">
                  <div class="item_title_txt">主营业务</div>
                  <div class="item_title_null"></div>
                </div>
                <div class="right_item_contact" v-html="mainBusiness">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'about',
  data() {
    return {
      //横幅图
      banners: [
        {
          id: 0,
          url: "/statics/banner/about/gsjstopbg.png"
        },
        {
          id: 1,
          url: "/statics/banner/about/qywhtopbg.png"
        },
        {
          id: 2,
          url: "/statics/banner/about/zzjgtopbg.png"
        },
        {
          id: 3,
          url: "/statics/banner/about/zyywtopbg.png"
        }
      ],
      //公司画册
      pictureAlbum: "",
      //关于安居tab下标
      aboutsTabIndex: 0,
      //关于安居tab
      aboutTabs: [
        {
          id: 0,
          title: "公司介绍"
        }, {
          id: 1,
          title: "企业文化"
        }, {
          id: 2,
          title: "组织架构"
        }, {
          id: 3,
          title: "主营业务"
        },
      ],
      //公司介绍
      coIntroduction: "",
      //公司定位
      coPositioning: "",
      //企业文化
      coCulture: "",
      //组织架构
      orStructure: "",
      //主营业务
      mainBusiness: "",
    }
  },
  created() {

  },
  watch: {
    //监听路由变化
    $route: {
      handler() {
        this.getBanners();
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //关于安居tab点击事件
    aboutsTabClick(id) {
      this.aboutsTabIndex = id;
    },
    //获取数据
    getData() {
      this.getAlbum();
      this.getCoIntroduction();
      this.getCoPositioning();
      this.getCoCulture();
      this.getOrStructure();
      this.getMainBusiness();
    },
    //获取横幅图
    async getBanners() {
      let banners1 = await this.request.get('/banner/getInfo?key=' + this.config.key + "&bannerType=1")
      if (banners1.data && banners1.data.fileId) {
        this.banners[0].url = this.preview + banners1.data.fileId;
      }
      let banners2 = await this.request.get('/banner/getInfo?key=' + this.config.key + "&bannerType=2")
      if (banners2.data && banners2.data.fileId) {
        this.banners[1].url = this.preview + banners2.data.fileId;
      }
      let banners3 = await this.request.get('/banner/getInfo?key=' + this.config.key + "&bannerType=3")
      if (banners3.data && banners3.data.fileId) {
        this.banners[2].url = this.preview + banners3.data.fileId;
      }
      let banners4 = await this.request.get('/banner/getInfo?key=' + this.config.key + "&bannerType=4")
      if (banners4.data && banners4.data.fileId) {
        this.banners[3].url = this.preview + banners4.data.fileId;
      }
    },
    //获取公司画册
    getAlbum() {
      this.request.get('/album/getInfo?key=' + this.config.key).then(res => {
        this.pictureAlbum = (undefined === res.data) ? "" : res.data.fileId;
      });
    },
    //获取公司介绍
    getCoIntroduction() {
      this.request.get('/aboutConfig/getInfo?key=' + this.config.key + "&pageType=company_introduction").then(res => {
        this.coIntroduction = (undefined === res.data) ? "" : res.data.pageContent.replaceAll('src="http://www.0769aj.com:8580', 'src="http://www.0769aj.com');
      });
    },
    //获取公司定位
    getCoPositioning() {
      this.request.get('/aboutConfig/getInfo?key=' + this.config.key + "&pageType=company_positioning").then(res => {
        this.coPositioning = (undefined === res.data) ? "" : res.data.pageContent.replaceAll('src="http://www.0769aj.com:8580', 'src="http://www.0769aj.com');
      });
    },
    //获取企业文化
    getCoCulture() {
      this.request.get('/aboutConfig/getInfo?key=' + this.config.key + "&pageType=corporate_culture").then(res => {
        this.coCulture = (undefined === res.data) ? "" : res.data.pageContent.replaceAll('src="http://www.0769aj.com:8580', 'src="http://www.0769aj.com');
      });
    },
    //获取组织架构
    getOrStructure() {
      this.request.get('/aboutConfig/getInfo?key=' + this.config.key + "&pageType=organizational_structure").then(res => {
        this.orStructure = (undefined === res.data) ? "" : res.data.pageContent.replaceAll('src="http://www.0769aj.com:8580', 'src="http://www.0769aj.com');
      });
    },
    //获取主营业务
    getMainBusiness() {
      this.request.get('/aboutConfig/getInfo?key=' + this.config.key + "&pageType=main_business").then(res => {
        this.mainBusiness = (undefined === res.data) ? "" : res.data.pageContent.replaceAll('src="http://www.0769aj.com:8580', 'src="http://www.0769aj.com');
      });
    }
  }
}
</script>
<style>
@import "../assets/styles/pages/about.css";
</style>
